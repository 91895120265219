const options = [
    {
        key: "",
        label: "sidebar.home",
        leftIcon: "ion-home",
    },

    {
        key: "mdr",
        label: "sidebar.mdr",
        leftIcon: "ion-calculator",
    },
    {
        key: "settlement",
        label: "sidebar.settlement",
        leftIcon: "ion-archive",
    },
    {
        key: "partner",
        label: "sidebar.partner",
        leftIcon: "ion-ios-people",
    },
    {
        key: "domain",
        label: "sidebar.domain",
        leftIcon: "ion-ios-at",
    },
    {
        key: "profile",
        label: "sidebar.profile",
        leftIcon: "ion-battery-charging",
    },
    {
        key: "merchant",
        label: "sidebar.merchant",
        leftIcon: "ion-ios-cart",
    },
    {
        key: "bank",
        label: "sidebar.bank",
        leftIcon: "ion-cash",
    },
    {
        key: "admin",
        label: "sidebar.admin",
        leftIcon: "ion-ios-contact-outline",
    },
    {
        key: "role",
        label: "sidebar.role",
        leftIcon: "ion-android-hand",
    },
    {
        key: "activity",
        label: "sidebar.activity",
        leftIcon: "ion-android-walk",
    },
    {
        key: "serverLog",
        label: "sidebar.serverLog",
        leftIcon: "ion-monitor",
    },
    {
        key: "setting",
        label: "sidebar.setting",
        leftIcon: "ion-android-settings",
    },
];
export default options;
